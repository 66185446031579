.sidebar-field-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.text-xxs {
    font-size: 0.6rem !important;
}
