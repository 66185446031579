/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

@import "common";

.layout-menu {
  &.logout-menu {
    width: 93%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.progress-bar {
  height: 6px;
}

.block-ui-bg{
  background-color: #f9fafb4f !important;
}
